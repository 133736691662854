import { getShopItemLabelsSetup, getShopItemLabelsJson } from '@/services/product'
import { deleteShopItemLabels } from '@/services/shop-item-labels'
import searchForm from '@/components/searchForm'
import allButton from '@/components/allButton'
import createTable from '@/components/createTable'
import { mixin } from '@/mixins/mixin'
import jsonData from './jsonData.js'
export default {
  name: 'managerProduct',
  components: {
    searchForm,
    createTable,
    allButton
  },
  mixins: [mixin],
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  created () {
    this.queryData(getShopItemLabelsSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getShopItemLabelsSetup)
    this.isActive = true
  },
  methods: {
    // 排序
    handleSort (val) {
      this.$refs.searchForm.onSubmit(val)
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getShopItemLabelsJson(this.searchData).then(res => {
        if (res.data) {
          if (res.data) {
            this.tableData = []
            this.$nextTick(() => {
              this.tableData = res.data.objects
              this.tabData = res.data.scopes
              Object.assign(this.pageData, res.data.pagination)
            })
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'goodsManagerCommonLabelsAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'goodsManagerCommonLabelsDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'goodsManagerCommonLabelsEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteShopItemLabels)
      }
    },
    // 切换伸缩框
    handleChange (val) {
      // console.log(val)
    },
    // 删除数据
    // deleteClick (data) {
    //   // mock操作
    //   this.$confirm('是否删除该数据?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$store.state.app.loading = this.$loading(loading)
    //     deleteShopItemLabels(data.id.value).then(res => {
    //       this.$store.state.app.loading.close()
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       })
    //       this.search()
    //     }).catch(() => this.$store.state.app.loading.close())
    //   }).catch(() => {
    //   })
    // },
    // 数据的选择
    selectionChange (val) {
      this.selectionData = val
    }
  }
}
