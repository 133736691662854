import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'name',
    label: '',
    Object: 'value'
    // autoWidth: false,
    // width: '60'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
    // autoWidth: false,
    // width: '60'
  }, {
    prop: 'image',
    label: '',
    img: true,
    autoWidth: false,
    height: '100px',
    width: '60'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value',
    autoWidth: false,
    width: '120'
  }],
  dialogBasicData: [{
    collname: '基本信息',
    alterFormData: [{
      span: 6,
      label: '名称(中文)',
      type: 'input',
      prop: 'chineseName',
      rule: [
        { required: true, message: i18n.t('text.required'), trigger: ['blur', 'change'] }
      ]
    }, {
      span: 6,
      label: '名称(英文)',
      type: 'input',
      prop: 'englishName',
      rule: [
        { required: true, message: i18n.t('text.required'), trigger: ['blur', 'change'] }
      ]
    }, {
      span: 24,
      label: '图片',
      type: 'avatar',
      prop: 'image_url',
      file: 'img',
      limit: 1
    }]
  }],
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
  }, {
    prop: 'image',
    label: '',
    span: 24,
    Object: 'value'
  }]
}
